import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "./../components/layouts/Default"
import Seo from "./../components/layouts/Seo"
import Button from "./../components/common/Button"
import SvgFeature from "./../components/common/SvgFeature"
import FeatureSection from "./../components/containers/FeatureSection"
import Wizard from "./../components/forms/Wizard"
import MainData from "./../components/layouts/partials/pages/request/MainData"

export default function Page() {
  const data = useStaticQuery(graphql`
    query UeberUns {
      header: file(
        relativePath: { eq: "headers/dierda_startseite_header.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allGooglePlaces {
        nodes {
          result {
            address_components {
              long_name
              short_name
              types
            }
          }
        }
      }
    }
  `)

  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="var(--primary)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Über uns"
        description="Seit vielen Jahren glaube ich daran, dass eine Beratungsphilosophie in der Kunden so beraten werden, wie Du selbst beraten werden möchtest - aufrichtig, individuell, ganzheitlich, transparent - langfristig der einzig richtige Weg sein kann."
        path="/ueber-uns/"
      />
      <div className="bg-white overflow-hidden">
        <div className="relative max-w-7xl mx-auto my-8 md:my-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Infos
            </p>
            <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Über uns
            </h1>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
              <svg
                className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <Img
                    fluid={data.header.childImageSharp.fluid}
                    className={`rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto`}
                  />
                  <figcaption class="flex mt-3 text-sm text-gray-500">
                    Florian Dierda
                  </figcaption>
                </figure>
              </div>
            </div>
            <div>
              <div className="mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p className="text-xl leading-7 text-indigo-500 mb-6 italic mx-auto max-w-xl lg:mx-0">
                  &bdquo;Finanzplanung - von Menschen für Menschen&ldquo;
                </p>
                <div className="mb-5 mx-auto max-w-xl lg:mx-0">
                  <div className="prose  text-gray-700 mb-6">
                    <p>
                      Seit vielen Jahren glaube ich daran, dass eine
                      Beratungsphilosophie in der Kunden so beraten werden, wie
                      Du selbst beraten werden möchtest - aufrichtig,
                      individuell, ganzheitlich, transparent - langfristig der
                      einzig richtige Weg sein kann. Mit klaren, nicht
                      verbiegbaren Leitsätzen, stehe ich zu meinen
                      Überzeugungen. Was ich denke, wird deutlich gesagt. Was
                      ich sage, wird auch umgesetzt. Ich stehe zu meinen
                      Maßnahmen. Was ich mache, mache ich konsequent!
                    </p>
                  </div>
                  <Button to="#infos" text="Was uns besonders macht" primary />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeatureSection title="Was uns besonders macht" id="infos">
        <SvgFeature
          svg={svg.check}
          title="Ehrlich und fair "
          text="Wir möchten absolut ehrlich, fair und transparent zu Dir sein. Das ist uns wichtig."
        />
        <SvgFeature
          svg={svg.check}
          title="Individuell nach Deinen Bedürfnissen"
          text="Jeder Mensch ist individuell – so sollte er auch behandelt werden."
        />
        <SvgFeature
          svg={svg.check}
          title="Ungebunden und unabhängig"
          text="Wir sind an keine Gesellschaft gebunden und planen so ein individuelles Konzept, welches auf Deine Wünsche zugeschnitten ist."
        />
        <SvgFeature
          svg={svg.check}
          title="Kostenfreie Beratung"
          text="Wir beraten Dich natürlich kostenlos."
        />
      </FeatureSection>

      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2" />
        </div>
        <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
          <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 ">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                Kontaktdaten
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-700">
                Hast Du Fragen? Wir sind gerne für Dich da. Fülle das Formular
                aus oder ruf uns gerne an.
              </p>
              <dl className="mt-8 text-base leading-6 text-gray-700">
                <div>
                  <dt className="sr-only">Adresse</dt>
                  <dd>
                    <p className="mt-4 text-gray-700 text-base leading-6">
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[1].long_name
                      }{" "}
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[0].long_name
                      }
                      ,<br />
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[7].long_name
                      }{" "}
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[3].long_name
                      }
                    </p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Telefon</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">
                      <a href="tel:+4915164406444">01516 440 6444</a>
                    </span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">E-Mail</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">
                      <a href="mailto:anfragen@dierda.de">anfragen@dierda.de</a>
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="max-w-lg mx-auto lg:max-w-none">
              <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                Nachricht schreiben
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-700">
                Fülle das Formular aus und wir melden uns zeitnah bei Dir.
              </p>
              <form
                action="https://formbackend.dierda.de/contact.php"
                method="POST"
              >
                <input type="hidden" name="Anfrage" value="Über-Uns-Seite" />
                <Wizard>
                  <MainData />
                </Wizard>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
